import { FC, PropsWithChildren } from "react";
import styles from "./ProgramsCard.module.scss";
import { useTypedSelector } from "@/hooks/useTypedSelector";
import clsx from "clsx";
import ProgramsCardDirection from "./Direction";
import useQuestionnaire from "@/hooks/useQuestionnaire";
import { LanguagesLocalesEnum } from "@/types/LanguagesLocalesEnum";
import useTranslation from "next-translate/useTranslation";

type ProgramsCardPropsType = {
  group: IProgramGroup;
  openModal: () => void;
  closeModal: () => void;
  chosenIds?: string[];
  id?: string;
};

const ProgramsCard: FC<PropsWithChildren<ProgramsCardPropsType>> = (props) => {
  const { group, openModal, closeModal } = props;
  const { theme } = useTypedSelector(state => state.user);
  const { chosenPrograms } = useTypedSelector(state => state.form);
  const { studentDetails: studentDetailActive } = useQuestionnaire();

  const { lang } = useTranslation("programs");

  const selectedDegree = studentDetailActive.degree;

  const onProgramChosen = () => {
    openModal();
  };

  const onProgramUnchosen = () => {
    openModal();
    chosenPrograms && chosenPrograms.length - 1 === 0 && setTimeout(closeModal, 1000);
  };

  return (
    <div className={clsx(styles.card, theme === "accessible" && styles.card_accessible)} id={props.id}>
      <div className={styles.card__title} id={`${props.id}_programTitle`}>
        {
          selectedDegree === "postgraduate" &&
        <span className={styles.card__title} id={`programTitle_${group.programs[0].id}`}>
          {group.name}
        </span>
        }
        {
          ["bachelor", "master"].includes(selectedDegree) &&
        <a className={styles.card__title}
          href={`https://abit.itmo.ru${lang === LanguagesLocalesEnum.EN ? "/en" : ""}/program/${group.programs[0].degree}/${group.slug}`}
          id={`programTitle_${group.programs[0].id}`}
          rel={"noopener noreferrer"}
          target="_blank">
          {group.name}
        </a>
        }
      </div>

      {group.programs.map(program => (
        <ProgramsCardDirection
          baseIdName={props.id}
          degree={selectedDegree}
          key={program.id}
          program={program}
          slug={group.slug}
          onProgramChosen={onProgramChosen}
          onProgramUnchosen={onProgramUnchosen}
        />
      )
      )}
    </div>
  );
};

export default ProgramsCard;
