import clsx from "clsx";
import { Dispatch, FC, PropsWithChildren, SetStateAction } from "react";
import { PaginationParams } from "../types/paginationTypes";
import ButtonCustom from "../../ButtonCustom";

type PageButtonProps = {
  page: number;
  targetPage: number;
  setPage: (page: number) => void;
  setParams?: Dispatch<SetStateAction<PaginationParams>>;
  params?: PaginationParams;
  classes: {
    button: string;
    numberButton: string;
    numberActiveButton: string;
  };
};

const PageButton: FC<PropsWithChildren<PageButtonProps>> = (props) => {
  const { page, targetPage, setPage, setParams, params, classes } = props;
  const isActive = page === targetPage;

  return (
    <ButtonCustom
      variant={isActive ? "primary" : "outline-primary"}
      className={clsx(
        classes.button,
        classes.numberButton,
        isActive && classes.numberActiveButton
      )}
      onClick={() => {
        setPage(targetPage);
        setParams && params && setParams({ ...params, page: targetPage });
      }}
    >
      {targetPage.toString()}
    </ButtonCustom>
  );
};

export default PageButton;
