const returnChangeString = (num: number, strings: string[]): string => {
  num = Math.abs(num) % 100;
  const n1 = num % 10;
  if (num > 10 && num < 20) {
    return strings[2];
  }
  else if (n1 > 1 && n1 < 5) {
    return strings[1];
  }
  else if (n1 == 1) {
    return strings[0];
  }
  return strings[2];
};

export default returnChangeString;
