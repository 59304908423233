import { FC, PropsWithChildren, useState, KeyboardEvent } from "react";
import { Form } from "react-bootstrap";
import useTranslation from "next-translate/useTranslation";
import SearchIcon from "@/public/images/symbols/search.svg";
import styles from "./ProgramsSearch.module.scss";
import clsx from "clsx";
import { useTypedSelector } from "@/hooks/useTypedSelector";
import ButtonCustom from "@/components/_Common/ButtonCustom";

type ProgramsSearchPropsType = {
  onSubmit: (query: string) => void;
};

const ProgramsSearch: FC<PropsWithChildren<ProgramsSearchPropsType>> = ({
  onSubmit,
}): JSX.Element => {
  const { t } = useTranslation("programs");
  const { theme } = useTypedSelector((state) => state.user);
  const [query, setQuery] = useState("");

  const submit = () => {
    onSubmit(query);
  };

  return (
    <>
      <Form.Group
        className={clsx(
          styles.search,
          theme === "accessible" && styles.search_accessible
        )}
      >
        <SearchIcon className={styles.search__icon} />
        <Form.Control
          className={styles.search__input}
          id={"programsSearchField"}
          name="search"
          placeholder={t("search.placeholder")}
          type="text"
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={(e: KeyboardEvent) => {
            if (e.code !== "Enter") return;
            submit();
          }}
        />

        <ButtonCustom className={styles.search__btn} id={"programsSearchBtn"} onClick={submit}>
          {t("search.btn")}
        </ButtonCustom>
      </Form.Group>
    </>
  );
};

export default ProgramsSearch;
