import {
  Dispatch,
  FC,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
} from "react";
import PageButton from "./PageButton";
import { PaginationParams } from "./types/paginationTypes";
import ButtonCustom from "../ButtonCustom";
import useTranslation from "next-translate/useTranslation";

type PaginationProps = {
  limit: number;
  total: number;
  page: number;
  params?: PaginationParams;
  setPage: (page: number) => void;
  setParams?: Dispatch<SetStateAction<PaginationParams>>;
  classes: {
    container: string;
    button: string;
    navButton: string;
    numberButton: string;
    numberActiveButton: string;
  };
  prevButtonContent?: ReactNode;
  nextButtonContent?: ReactNode;
};

const BUTTONS_COUNT = 3;

const Pagination: FC<PropsWithChildren<PaginationProps>> = (props) => {
  const {
    limit,
    total,
    page,
    params,
    setPage,
    setParams,
    classes,
    prevButtonContent,
    nextButtonContent,
  } = props;
  const pagesCount = Math.ceil(total / limit);
  const { t } = useTranslation("programs");
  const RenderPrevPageButton = () => (
    <ButtonCustom
      className={classes.navButton}
      disabled={page == 1}
      size="lg"
      onClick={() => {
        setPage(page - 1);
        setParams && params && setParams({ ...params, page: params.page - 1 });
      }}
    >
      {nextButtonContent ?? t("pagination.button.left")}
    </ButtonCustom>
  );

  const RenderNextPageButton = () => (
    <ButtonCustom
      className={classes.navButton}
      disabled={page == pagesCount}
      size="lg"
      onClick={() => {
        setPage(page + 1);
        setParams && params && setParams({ ...params, page: params.page + 1 });
      }}
    >
      {prevButtonContent ?? t("pagination.button.right")}
    </ButtonCustom>
  );

  const RenderFirstPageButton = () => (
    <PageButton
      classes={{ ...classes }}
      page={page}
      params={params}
      setPage={setPage}
      setParams={setParams}
      targetPage={1}
    />
  );

  const RenderLastPageButton = () => (
    <PageButton
      classes={{ ...classes }}
      page={page}
      params={params}
      setPage={setPage}
      setParams={setParams}
      targetPage={pagesCount}
    />
  );
  const RenderDotsItem = () => <span className={classes.navButton}>…</span>;

  const RenderArrayOfPageButtons = ({
    length,
    valueFormatter,
  }: {
    length: number;
    valueFormatter: (i: number) => number;
  }) => (
    <>
      {Array.from({ length }, (_, i) => valueFormatter(i)).map((v) => (
        <PageButton
          classes={{ ...classes }}
          key={v}
          page={page}
          params={params}
          setPage={setPage}
          setParams={setParams}
          targetPage={v}
        />
      ))}
    </>
  );

  const renderLeftSide = () => (
    <>
      <RenderArrayOfPageButtons
        length={BUTTONS_COUNT + 1}
        valueFormatter={(i) => i + 2}
      />
      <RenderDotsItem />
    </>
  );

  const renderCenterSide = () => (
    <>
      <RenderDotsItem />
      <RenderArrayOfPageButtons
        length={BUTTONS_COUNT}
        valueFormatter={(i) => i + page - 1}
      />
      <RenderDotsItem />
    </>
  );

  const renderRightSide = () => (
    <>
      <RenderDotsItem />
      <RenderArrayOfPageButtons
        length={BUTTONS_COUNT + 1}
        valueFormatter={(i) => i + pagesCount - BUTTONS_COUNT - 1}
      />
    </>
  );

  const renderAll = () => (
    <RenderArrayOfPageButtons
      length={pagesCount}
      valueFormatter={(i) => i + 1}
    />
  );

  if (pagesCount <= 1) return <div className={classes.container} />;

  let renderFunc = renderCenterSide;
  if (pagesCount <= BUTTONS_COUNT + 2) renderFunc = renderAll;
  else if (page - BUTTONS_COUNT <= 0) renderFunc = renderLeftSide;
  else if (pagesCount - page <= BUTTONS_COUNT - 1) renderFunc = renderRightSide;

  return (
    <div className={classes.container}>
      <RenderPrevPageButton />
      {renderFunc !== renderAll ? <RenderFirstPageButton /> : null}

      {renderFunc()}

      {renderFunc !== renderAll ? <RenderLastPageButton /> : null}
      <RenderNextPageButton />
    </div>
  );
};

export default Pagination;
