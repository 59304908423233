import { FC, PropsWithChildren, useEffect, useState, ChangeEvent, KeyboardEvent } from "react";
import { Range, getTrackBackground } from "react-range";
import { Col, Form, Row } from "react-bootstrap";
import FormLabel from "@/components/_Common/Form/Label";
import { useForm } from "react-hook-form";
import styles from "./RangeBar.module.scss";
import useTranslation from "next-translate/useTranslation";

type RangeBarProps = {
  step?: number,
  min?: number,
  max?: number,
  updatePrices: (values: number[]) => void,
};

const RangeBar: FC<PropsWithChildren<RangeBarProps>> = (props): JSX.Element => {
  const { step = 1000, min = 200000, max = 500000, updatePrices } = props;
  const [values, setValues] = useState([min, max]);
  const { setValue, register, watch } = useForm();
  const { t } = useTranslation("common");

  const watchMin = watch("minValue");
  const watchMax = watch("maxValue");

  useEffect(() => {
    updatePrices([watchMin, watchMax]);
  }, [watchMin, watchMax]);

  useEffect(() => {
    setValue("minValue", Number(values[0]));
    setValue("maxValue", Number(values[1]));
  }, [values]);

  useEffect(() => {
    setValues([min, max]);
  }, [min, max]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    const formValue = parseInt(e.target.value ? e.target.value : "0");

    if (value === "min") {
      if (formValue < min) {
        const newValues = [min, values[1]];
        setValues(newValues);
      } else if (formValue > values[1]) {
        const newValues = [values[1], values[1]];
        setValues(newValues);
      } else {
        const newValues = [(Math.floor(formValue / step) * step), values[1]];
        setValues(newValues);
      }
    } else if (value === "max") {
      if (formValue > max) {
        const newValues = [values[0], max];
        setValues(newValues);
      } else if (formValue < values[0]) {
        const newValues = [values[0], values[1]];
        setValues(newValues);
      } else {
        const newValues = [values[0], (Math.ceil(formValue / step) * step)];
        setValues(newValues);
      }
    }
  };

  return (
    <>
      <div className={styles.range}>
        <Range
          draggableTrack
          max={max}
          min={min}
          step={step}
          values={values}
          renderThumb={({ props }) => (
            <div {...props} className={styles.range__thumb} />
          )}
          renderTrack={({ props, children }) => (
            <div
              className={styles.range__track}
              onMouseDown={props.onMouseDown}
              onMouseUp={() => updatePrices && updatePrices(values)}
              onTouchEnd={() => updatePrices && updatePrices(values)}
              onTouchStart={props.onTouchStart}
            >
              <div
                className={styles.range__background}
                ref={props.ref}
                style={{
                  background: getTrackBackground({
                    values,
                    colors: ["#D4D6D7", "#2E3542", "#D4D6D7"],
                    min: min,
                    max: max,
                  }),
                }}
              >
                {children}
              </div>
            </div>
          )}
          onChange={(values) => setValues(values)}
        />
      </div>

      <Row>
        <Form.Group as={Col} className={styles.range__prices} sm={6}>
          <FormLabel>
            {t("range.min")}
          </FormLabel>
          <Form.Control
            {...register("minValue")}
            defaultValue={min}
            name='minValue'
            type="text"
            onBlur={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, "min")}
            onKeyPress={(e: KeyboardEvent & ChangeEvent<HTMLInputElement>) => {
              if (e.key === "Enter") e.target.blur();
            }}
          />
        </Form.Group>
        <Form.Group as={Col} className={styles.range__prices} sm={6}>
          <FormLabel>
            {t("range.max")}
          </FormLabel>
          <Form.Control
            {...register("maxValue")}
            defaultValue={max}
            name='maxValue'
            type="text"
            onBlur={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, "max")}
            onKeyPress={(e: KeyboardEvent & ChangeEvent<HTMLInputElement>) => {
              if (e.key === "Enter") e.target.blur();
            }}
          />
        </Form.Group>
      </Row>
    </>
  );
};

export default RangeBar;
