import clsx from "clsx";
import React, { ChangeEvent, FC, PropsWithChildren, ReactNode } from "react";

import styles from "./RadioButton.module.scss";

type RadioButtonProps = {
  children: ReactNode;
  id?: string;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  additionalStyles?: string;
  checked?: boolean;
  disabled?: boolean;
};

const RadioButton: FC<PropsWithChildren<RadioButtonProps>> = (props) => {
  const { children, id, name, additionalStyles, onChange, checked, disabled } = props;

  return (
    <label className={clsx(styles.radioButton, additionalStyles)}>
      <input
        checked={checked}
        className={clsx(styles.radioButton__input)}
        disabled={disabled}
        id={id}
        name={name}
        type="radio"
        onChange={onChange}
      />
      <span className={clsx(styles.radioButton__label)}>{children}</span>
    </label>
  );
};

export default RadioButton;
