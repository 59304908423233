import { useTypedSelector } from "@/hooks/useTypedSelector";
import ProgramsModal from "@/layouts/_Common/ProgramsModal";
import dictionaryService from "@/lib/services/dictionary.service";
import userService from "@/lib/services/user.service";
import { useRouter } from "next/router";
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ProgramsCard from "./Cards";
import ProgramsCardsSkeleton from "./Cards/Skeleton";
import ProgramsHeadingList from "./HeadingList";
import ProgramsSearch from "./Search";
import ProgramsSidebar from "./Sidebar";
import styles from "./ProgramsPagination.module.scss";
import Pagination from "../_Common/Pagination";
import useQuestionnaire from "@/hooks/useQuestionnaire";
import useRoles from "@/hooks/useRoles";
import debounce from "lodash.debounce";
import { FormActions, setChosenPrograms } from "@Entities/Form";
import useTranslation from "next-translate/useTranslation";
import {
  programsAction,
  programsReducer,
} from "@Features/ProgramsForm/model/slice";
import {
  DynamicModuleLoader,
  ReducersList,
} from "@Shared/lib/DynamicModuleLoader";
import { Tooltip } from "@Shared/ui/Tooltip";

const PROGRAMS_LIMIT = 20;
const PROGRAMS_SKELETON_LIMIT = 5;

const reducers: ReducersList = {
  programReducer: programsReducer,
};

const defaultFilter = {
  directions: [],
  disciplines: [],
  opportunities: [],
  languages: "",
  types: [],
  faculties: [],
  priceFrom: "",
  priceTo: "",
};

export const Context = createContext<{
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}>({ loading: false, setLoading: () => null });

const ProgramsContainer: FC<PropsWithChildren> = () => {
  const { t, lang } = useTranslation("programs");

  const dispatch = useDispatch();
  const router = useRouter();
  const { chosenPrograms } = useTypedSelector((state) => state.form);
  const { year } = useTypedSelector((state) => state.user);
  const [loading, setLoading] = useState<boolean>(false);
  const { user, studentDetails: studentDetailActive } = useQuestionnaire();
  const { isUserClient } = useRoles();

  const { page } = router.query;
  const { pathname } = router;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groups, setGroups] = useState<{
    total: number;
    items: IProgramGroup[];
  } | null>(null);
  const error = useTypedSelector((state) => state.programReducer?.error);
  const [errorMessage, setErrorMessage] = useState({
    show: false,
    text: null,
  });

  useEffect(() => {
    if (error) {
      setErrorMessage((prev) => ({ ...prev, text: error, show: true }));
      dispatch(programsAction.resetError());
    }
  }, [error]);

  const getValidPage = (): number => {
    if (
      !page ||
      Number.isNaN(+page) ||
      (groups && +page > Math.ceil(groups?.total / PROGRAMS_LIMIT))
    )
      return 1;

    return +page;
  };

  const [params, setParams] = useState<{
    search: { query: string };
    filter: IFilters;
    page: number;
  }>({
    search: { query: "" },
    filter: defaultFilter,
    page: getValidPage(),
  });

  const getPrograms = useCallback(
    debounce(
      async (params, degree, year, origin) => {
        setGroups(null);

        const data = await userService.getPrograms(
          lang,
          year,
          studentDetailActive.degree,
          origin,
          params.page,
          PROGRAMS_LIMIT,
          params.search.query,
          params.filter.types,
          params.filter.faculties,
          params.filter.opportunities,
          params.filter.languages,
          params.filter.disciplines,
          params.filter.directions,
          params.filter.priceFrom,
          params.filter.priceTo
        );

        setGroups({
          total: data.metaData.total,
          items: data.result.groups,
        });
      },
      1000,
      { maxWait: 1200 }
    ),
    [studentDetailActive.id]
  );

  useEffect(() => {
    getPrograms(params, studentDetailActive.degree, year, location.origin);
  }, [params]);

  useEffect(() => {
    const getData = async () => {
      const directions = await dictionaryService.getDirections(
        lang,
        location.origin,
        studentDetailActive.degree
      );
      const disciplines = await dictionaryService.getExamDisciplines(
        lang,
        location.origin,
        studentDetailActive.degree,
        String(year)
      );

      dispatch(FormActions.setDictionaries({ directions, disciplines }));
      dispatch(
        setChosenPrograms({
          userId: user.id,
          studentDetailsId: studentDetailActive.id,
        })
      );

      setParams((prevValue) => ({
        ...prevValue,
        filter: defaultFilter,
        page: getValidPage(),
      }));
    };
    getData();
  }, [studentDetailActive]);

  const changePage = useCallback(
    async (page: number) => {
      if (isUserClient) {
        await router.push({
          pathname,
          query: { page },
        });
      } else {
        await router.push({
          pathname,
          query: {
            page,
            id: router.query.id,
            userId: router.query.userId,
            studentDetailsId: router.query.studentDetailsId,
          },
        });
      }
    },
    [params]
  );

  return (
    <div id={"programsSearchPageWrapper"}>
      <DynamicModuleLoader reducers={reducers}>
        {errorMessage.text !== null && errorMessage.show && (
          <Tooltip
            text={errorMessage.text}
            title={t("errorStaticText")}
            variant="error"
            onShowTimeout={() =>
              setErrorMessage((prev) => ({ ...prev, show: false }))
            }
          />
        )}
        <ProgramsModal
          closeModal={() => setIsModalOpen(false)}
          showModal={isModalOpen}
        />
        <h1 className="mb-4 mt-4 text-sm-left text-center">{t("heading")}</h1>
        <ProgramsSearch
          onSubmit={(query) =>
            setParams((prevValue) => ({
              ...prevValue,
              search: { query },
              page: 1,
            }))
          }
        />
        <div className="d-flex mt-sm-5 mt-4 align-items-start row">
          <ProgramsSidebar
            filterValues={params.filter}
            setFilterValues={(filter) => {
              setParams((prevValue) => ({ ...prevValue, filter, page: 1 }));
            }}
          />

          <Col className="pl-lg-3" id={"programsWrapper"} lg={8}>
            <ProgramsHeadingList count={groups?.total ?? 0} />
            {groups ? (
              groups.total > 0 ? (
                <>
                  <Context.Provider value={{ loading, setLoading }}>
                    {groups.items.map((group, index) => (
                      <ProgramsCard
                        closeModal={() => setIsModalOpen(false)}
                        group={group}
                        id={`programsCard_${index}`}
                        key={index}
                        openModal={() => setIsModalOpen(true)}
                        chosenIds={chosenPrograms?.map(
                          (item) => "program" + item?.program?.id
                        )}
                      />
                    ))}
                  </Context.Provider>
                  <Pagination
                    limit={PROGRAMS_LIMIT}
                    page={params.page}
                    params={params}
                    setPage={(page: number) => changePage(page)}
                    setParams={setParams}
                    total={groups.total}
                    classes={{
                      container: styles.pagination,
                      button: styles.item,
                      navButton: styles["nav-item"],
                      numberButton: styles.item__number,
                      numberActiveButton: styles.item__number_active,
                    }}
                  />
                </>
              ) : (
                <p>{t("filter.notFound")}</p>
              )
            ) : (
              Array.from(Array(PROGRAMS_SKELETON_LIMIT).keys()).map((index) => (
                <ProgramsCardsSkeleton
                  key={index}
                  uniqueKey={`programs-${index}`}
                />
              ))
            )}
          </Col>
        </div>
      </DynamicModuleLoader>
    </div>
  );
};

export default ProgramsContainer;
