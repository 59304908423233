import useTranslation from "next-translate/useTranslation";
import {
  FC,
  PropsWithChildren,
  useContext,
  useState,
  ChangeEvent,
} from "react";
import FormCheck from "@/components/_Common/Form/Checkbox";
import styles from "../ProgramsCard.module.scss";
import userService from "@/lib/services/user.service";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "@/hooks/useTypedSelector";
import { captureException } from "@sentry/nextjs";
import FinancingModal from "@/layouts/_Common/ModalItems/Financing";
import UserService from "@/lib/services/user.service";

import { Context } from "../..";
import useQuestionnaire from "@/hooks/useQuestionnaire";
import { setChosenPrograms } from "@Entities/Form";
import { programsAction } from "@Features/ProgramsForm/model/slice";

type ProgramsCardDirectionProps = {
  program: IProgram;
  baseIdName?: string;
  onProgramChosen: () => void;
  onProgramUnchosen: () => void;
  slug?: string;
  degree?: string;
};

const ProgramsCardDirection: FC<
  PropsWithChildren<ProgramsCardDirectionProps>
> = ({ program, baseIdName, onProgramChosen, onProgramUnchosen, slug, degree }) => {
  const { t } = useTranslation("programs");
  const dispatch = useDispatch();
  const { chosenPrograms } = useTypedSelector((state) => state.form);

  const { loading, setLoading } = useContext(Context);
  const [modalOpen, setModalOpen] = useState("");
  const [checked, setChecked] = useState<boolean>(false);
  const questionnaire = useTypedSelector((state) => state.window.questionnaire);
  const { user, studentDetails: studentDetailActive } = useQuestionnaire();

  const updateChosenPrograms = () =>
    dispatch(
      setChosenPrograms({
        userId: user.id,
        studentDetailsId: studentDetailActive.id,
      })
    );

  const setFinancing = async (
    selectedProgram: string | number,
    financing: string
  ) => {
    try {
      await UserService.changeFinancing(
        user.id,
        studentDetailActive.id,
        selectedProgram,
        financing,
        dispatch
      );
      updateChosenPrograms();
    } catch (error) {
      captureException(error);
    }
  };

  const chooseProgram = async (
    e: ChangeEvent<HTMLInputElement> | string,
    groupId?: number,
    financing?: string
  ) => {
    setLoading(true);
    setChecked(true);

    const data: ISendProgram = {
      program: {
        id: program.id,
      },
      competitiveGroup: {
        id: groupId as number,
      },
      financing: financing ?? null,
    };

    try {
      await userService.addUserPrograms(
        user.id,
        studentDetailActive.id,
        data,
        dispatch
      );
      dispatch(
        setChosenPrograms({
          userId: user.id,
          studentDetailsId: studentDetailActive.id,
        })
      );
      onProgramChosen();
      setLoading(false);
    } catch (error: any) {
      dispatch(
        programsAction.setError(
          error?.data?.errors?.violations?.at(0)?.title || error.message
        )
      );
      captureException(error);
      setLoading(false);
      setChecked(false);
    }
  };

  const unchooseProgram = async (
    e: ChangeEvent<HTMLInputElement>,
    deletebleItem?: Program
  ) => {
    setLoading(true);
    setChecked(false);
    const programToDelete = deletebleItem;
    if (!programToDelete) return;
    try {
      await userService.deleteUserPrograms(
        user.id,
        studentDetailActive.id,
        Number(programToDelete.id)
      );
      dispatch(
        setChosenPrograms({
          userId: user.id,
          studentDetailsId: studentDetailActive.id,
        })
      );
      onProgramUnchosen();
      setLoading(false);
      setChecked(false);
    } catch (error) {
      captureException(error);
      setLoading(false);
      setChecked(true);
    }
  };

  const isDefaultChecked = (groupId?: number) => {
    if (!groupId)
      return (
        (chosenPrograms || [])?.filter((item) => item.program.id === program.id)
          .length > 0
      );
    return (
      (chosenPrograms || [])?.filter(
        (item) =>
          item.program.id === program.id &&
          item.competitiveGroup?.id === groupId
      ).length > 0
    );
  };

  const competitiveGroup = program.competitiveGroup;
  const competitiveGroupId = competitiveGroup?.id;
  const id = competitiveGroup ? competitiveGroup.id : program.id;
  const budget = competitiveGroup?.admissionQuotas?.budget;
  const contract = competitiveGroup?.admissionQuotas?.contract;
  const code = String(program.code);
  const directionCode = program.directionCode;
  const directionOfEducation = program.directionOfEducation;
  const name = program.title;

  return (
    <>
      <FinancingModal
        budget={budget}
        chooseProgram={chooseProgram}
        code={code}
        competitiveGroupId={competitiveGroupId}
        contract={contract}
        degree={degree}
        directionCode={directionCode}
        directionOfEducation={directionOfEducation}
        isAll={"all"}
        name={name}
        selectedProgramId={id}
        setFinancing={setFinancing}
        showModal={modalOpen === "financingOpen"}
        slug={slug}
        closeModal={() => {
          setModalOpen("");
        }}
      />
      <div className={styles.card__item} id={`${baseIdName}_directionTitle`} key={id}>
        <p className={styles.card__text} id={`directionTitle_${id}`}>{directionOfEducation}</p>
        <FormCheck
          additionalStyles={styles.card__checkbox}
          checked={checked || isDefaultChecked(competitiveGroupId)}
          disabled={(!questionnaire && studentDetailActive.locked) || loading}
          extraChecked={isDefaultChecked(competitiveGroupId)}
          id={`idProgram${program.id}_idDirection${id}`}
          mode={"dark"}
          name={`${program.id}-${id}`}
          onChange={(e) => {
            if (e.target.checked) {
              setModalOpen("financingOpen");
            } else {
              const deletableProgram = chosenPrograms?.filter(
                (item) => item.program.id === program.id && item
              );
              deletableProgram?.forEach((item) => {
                unchooseProgram(e, item);
              });
              e.target.checked = false;
            }
          }}
        >
          {t("checkbox.label")}
        </FormCheck>
      </div>
    </>
  );
};

export default ProgramsCardDirection;
