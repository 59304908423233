import Link from "next/link";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import styles from "./ProgramsSidebar.module.scss";
import useTranslation from "next-translate/useTranslation";
import CalcIcon from "@/public/images/symbols/calc.svg";
import FilterIcon from "@/public/images/symbols/filter.svg";
import clsx from "clsx";
import { useTypedSelector } from "@/hooks/useTypedSelector";
import DictionaryService, { IDictionaryItem, IFacultyItem } from "@/lib/services/dictionary.service";
import ProgramsFilter from "@/components/Programs/Filter";
import { lowerFirstLetter } from "@/lib/_common/lowerFirstLetter";
import useQuestionnaire from "@/hooks/useQuestionnaire";
import { LanguagesLocalesEnum } from "@/types/LanguagesLocalesEnum";

const languagesRU = [
  {
    label: "Русский",
    value: "Русский",
  },
  {
    label: "Английский",
    value: "Английский",
  },
  {
    label: "Русский и Английский",
    value: "Русский, Английский",
  },
];

const languagesEN = [
  {
    label: "Russian",
    value: "Русский",
  },
  {
    label: "English",
    value: "Английский",
  },
  {
    label: "Russian and English",
    value: "Русский, Английский",
  },
];

const opportunities: IDictionaryItem[] = [
  {
    label: "Совместная образовательная программа (СОП)",
    value: "СОП",
  },
  {
    label: "Online магистратура",
    value: "ОНЛАЙН",
  },
  {
    label: "Проектная магистратура",
    value: "ПРОЕКТНАЯ",
  },
];

const opportunitiesEn: IDictionaryItem[] = [
  {
    label: "Joint educational program (JEP)",
    value: "JEP",
  },
  {
    label: "Online",
    value: "Online",
  },
  {
    label: "Project-based",
    value: "project-based",
  },
];

type ProgramsSidebarProps = {
  filterValues: IFilters,
  setFilterValues: (filterValues: IFilters) => void,
};

const ProgramsSidebar: FC<PropsWithChildren<ProgramsSidebarProps>> = (props): JSX.Element => {
  const { setFilterValues, filterValues } = props;
  const { dictionaries } = useTypedSelector(state => state.form);
  const { directions, disciplines } = dictionaries as IFiltersDictionaries;
  const { t, lang } = useTranslation("programs");
  const { theme } = useTypedSelector(state => state.user);
  const [showDirections, setShowDirections] = useState<boolean>(false);
  const [showOpportunities, setShowOpportunities] = useState<boolean>(false);
  const [showFaculties, setShowFaculties] = useState<boolean>(false);
  const [showDisciplines, setShowDisciplines] = useState<boolean>(false);
  const [showLanguages, setShowLanguages] = useState<boolean>(false);
  const [showPrice, setShowPrice] = useState<boolean>(false);
  const [mobileToggle, setMobileToggle] = useState<boolean>(false);
  const [faculties, setFaculties] = useState<IDictionaryItem[]>([]);
  const areFiltersChecked =
    filterValues.opportunities.length > 0
    || filterValues.languages.length > 0
    || filterValues.disciplines.length > 0
    || filterValues.directions.length > 0
    || filterValues.types.length > 0
    || filterValues.faculties.length > 0
    || filterValues.priceTo
    || filterValues.priceFrom;

  const { studentDetails: studentDetailActive } = useQuestionnaire();
  useEffect(() => {
    DictionaryService.getFaculties(lang, studentDetailActive.degree).then((items) => {
      setFaculties(items.map((item: IFacultyItem) => ({ value: String(item.id), label: lowerFirstLetter(item.label) })));
    });
  }, []);

  useEffect(() => {
    clearFilters();
  }, [studentDetailActive]);

  const clearFilters = () => {
    setShowDirections(false);
    setShowOpportunities(false);
    setShowLanguages(false);
    setShowPrice(false);
    setShowDisciplines(false);
    setShowFaculties(false);
  };

  const handleReset = () => {
    setMobileToggle(false);
    setFilterValues({
      directions: [],
      disciplines: [],
      opportunities: [],
      languages: "",
      types: [],
      faculties: [],
      priceFrom: "",
      priceTo: "",
    });
    clearFilters();
  };

  const handleApply = () => {
    setMobileToggle(false);
  };

  return (
    <aside className={clsx(styles.sidebar, "col-lg-4 pr-lg-3 flex-lg-column flex-row", mobileToggle && styles.sidebar__mobile)} id={"programsSidebarWrapper"}>
      <Link
        className={clsx(styles.button, "col-lg-12 col-sm-6 col-9 d-none")}
        href='https://abit.itmo.ru/calculator/'
        target='_blank'
      >
        <CalcIcon className={styles.button__icon} />
        {t("calc_text")}
      </Link>

      {
        !mobileToggle && <button className={clsx(styles.button, styles.button_filter, "col-lg-12 d-lg-none d-flex ml-2")} onClick={() => setMobileToggle(true)}>
          <FilterIcon className={clsx(styles.button__icon, styles.button__icon_filter)} />
          <span className='d-sm-inline-block d-none'>{t("filter.heading")}</span>
        </button>
      }

      <div className={clsx(styles.filter, theme === "accessible" && styles.filter_accessible, mobileToggle && styles.filter__mobile, "d-lg-block")}>
        <div className={clsx(mobileToggle && styles.filter__title_wrapper)}>
          <h3 className={styles.filter__title}>
            {t("filter.heading")}
          </h3>
          {
            mobileToggle && <span className={clsx(styles.filter__close)} onClick={() => setMobileToggle(false)} />
          }
        </div>
        {areFiltersChecked && !mobileToggle &&
          <p className={styles.filter__reset} onClick={handleReset}>
            {t("filter.reset")}
          </p>}

        <ProgramsFilter
          filterValues={filterValues}
          isMobile={mobileToggle}
          items={directions}
          setFilterValues={setFilterValues}
          showFilter={showDirections}
          toggleShowFilter={() => setShowDirections(!showDirections)}
          variant='directions'
        />
        {studentDetailActive.degree === "master" &&
          <><ProgramsFilter
            filterValues={filterValues}
            isMobile={mobileToggle}
            items={lang === LanguagesLocalesEnum.RU ? opportunities : opportunitiesEn}
            setFilterValues={setFilterValues}
            showFilter={showOpportunities}
            toggleShowFilter={() => setShowOpportunities(!showOpportunities)}
            variant='types'
          />
          <ProgramsFilter
            filterValues={filterValues}
            isMobile={mobileToggle}
            items={lang === LanguagesLocalesEnum.RU ? languagesRU : languagesEN}
            setFilterValues={setFilterValues}
            showFilter={showLanguages}
            toggleShowFilter={() => setShowLanguages(!showLanguages)}
            variant='languages'
          /></>}

        <ProgramsFilter
          filterValues={filterValues}
          isMobile={mobileToggle}
          items={disciplines}
          setFilterValues={setFilterValues}
          showFilter={showDisciplines}
          toggleShowFilter={() => setShowDisciplines(!showDisciplines)}
          variant='disciplines'
        />
        {studentDetailActive.degree !== "postgraduate" &&
          <ProgramsFilter
            filterValues={filterValues}
            isMobile={mobileToggle}
            setFilterValues={setFilterValues}
            showFilter={showPrice}
            toggleShowFilter={() => setShowPrice(!showPrice)}
            variant='price'
          />}

        {studentDetailActive.degree !== "postgraduate" &&
          <ProgramsFilter
            filterValues={filterValues}
            isMobile={mobileToggle}
            items={faculties}
            setFilterValues={setFilterValues}
            showFilter={showFaculties}
            toggleShowFilter={() => setShowFaculties(!showFaculties)}
            variant='faculties'
          />}
      </div>
      {
        areFiltersChecked && mobileToggle &&
        <div className={styles.filter__buttons}>
          <button className={styles.filter__buttons_reset} type='button' onClick={handleReset}>{t("filter.reset")}</button>
          <button className={styles.filter__buttons_apply} type='button' onClick={handleApply}>{t("filter.apply")}</button>
        </div>
      }
    </aside>
  );
};

export default ProgramsSidebar;
