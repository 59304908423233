import { FC, PropsWithChildren } from "react";
import returnChangeString from "@/lib/_common/returnChangeString";
import useTranslation from "next-translate/useTranslation";
import styles from "./ProgramsHeadingList.module.scss";
import clsx from "clsx";

type ProgramsHeadingListPropsType = {
  count: number;
};

const ProgramsHeadingList: FC<PropsWithChildren<ProgramsHeadingListPropsType>> = ({ count }): JSX.Element => {
  const { t } = useTranslation("programs");

  return (
    <p className={clsx(styles.title, "text-sm-left text-center")} id={"programsList_countTitleWrapper"}>
      <span className='d-sm-inline-block d-block' id={"programsList_countNumber"}>{count}</span> {
        returnChangeString(count, [
          t("programs_texts.one.1"),
          t("programs_texts.one.2"),
          t("programs_texts.one.3"),
        ])
      } {
        returnChangeString(count, [
          t("programs_texts.two.1"),
          t("programs_texts.two.2"),
          t("programs_texts.two.3"),
        ])
      }
    </p>
  );
};

export default ProgramsHeadingList;
