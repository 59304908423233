import { FC, PropsWithChildren } from "react";
import ContentLoader from "react-content-loader";
import styles from "./ProgramsCardSkeleton.module.scss";

type ProgramsCardsSkeletonPropsType = {
  uniqueKey: string;
};

const ProgramsCardsSkeleton: FC<PropsWithChildren<ProgramsCardsSkeletonPropsType>> = (props): JSX.Element => {
  const uniqueKey = props.uniqueKey;

  return (
    <ContentLoader
      backgroundColor='#f3f3f3'
      className={styles.card}
      foregroundColor='#ecebeb'
      id={`skeletonPrograms_card_${uniqueKey}`}
      speed={2}
      uniqueKey={uniqueKey}
      viewBox='0 0 800 180'
    >
      <rect height="24" rx="0" ry="0" width="300" x="32" y="32" />
      <rect height="24" rx="0" ry="0" width="200" x="364" y="32" />
      <rect height="24" rx="0" ry="0" width="400" x="32" y="72" />

      <rect height="14" rx="0" ry="0" width="360" x="32" y="132" />
      <rect height="18" rx="0" ry="0" width="18" x="676" y="130" />
      <rect height="14" rx="0" ry="0" width="60" x="700" y="132" />
    </ContentLoader>
  );
};

export default ProgramsCardsSkeleton;
